.emailSuccess{
    display: grid;
    justify-content: center;
    align-items: center;
    background-color: rgb(226, 226, 226);
    height: 100vh;
    background-image: url('../img/emailSuccess/img2.webp');
    background-size: cover;
    
    .text{
        z-index: 2;
        display: grid;

        h1{
            animation: halfToUp 2s ease forwards;
            background: linear-gradient(90deg, #ececec 0%, #d6d6d6 100%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-weight: 600;
            font-size: 35px;
            opacity: 0%;
        }
        .underline{
            animation: changeWidth 2s .5s ease forwards;
            width: 200px;
            height: 1px;
            background-color: #dadada;
            justify-self: left;
            opacity: 0%;
        }
        a{
            animation: halfToUp 2s .6s ease forwards;
            margin-top: 50px;
            justify-self: center;
            padding: 10px 100px;
            border-radius: 10px;
            color: white;
            background-color: #630000;
            z-index: 100;
            opacity: 0%;
            transition: .5s ;
        }
        a:hover{
            scale: .9;
            background-color: #970707;
        }
    }
    
    .fundo{
        position: absolute;
        left: -20px;
        bottom: 50px;
        opacity: 30%;
        z-index: 1;
    }
    .fundo2{
        z-index: 1;
        position: absolute;
        right: 0px;
        top: 100px;
        opacity: 20%;
        max-width: 700px;
    }
}

@media screen and (max-width: 1440px){
    .emailSuccess{
        .text{
            h1{
                font-size: 30px;
            }
        }
    }
}

@media screen and (max-width: 768px){
    .emailSuccess{
        .text{
            padding: 0px 50px;
            a{
                padding: 10px 50px;
            }
        }
        .fundo{
            width: 30%;
        }
        .fundo2{
            width: 50%;
        }
    }
}

@media screen and (max-width: 425px){
    .emailSuccess{
        .text{
            padding: 0px 20px;
            h1{
                font-size: 25px;
            }
            .underline{
                width: 80px;
            }
            a{
                justify-self: left;
                max-width: 100%;
                padding: 5px 50px;
            }
        }
    }
}