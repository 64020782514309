.certifications {
    padding: 50px 0px 50px 0px;
    height: fit-content;
    width: 100%;
    display: grid;

    .certificationsTitle{
        opacity: 0%;
        transform: translateY(-50%);
        transition: 1s 0.5s ease;
    }
    .certificationsTitle.visible{
        opacity: 100%;
        transform: translateY(0%);
    }

    h1 {
        text-align: center;
        font-size: 25px;
        font-weight: 600;

        padding: 2rem 1rem 50px 1rem;

        background: linear-gradient(90deg, #000 30%, #D30B0B 60%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    .cards {
        overflow-x: hidden;
        padding: 20px 0px 20px 0px;
        max-width: 100%;
        display: flex;

        .card {
            padding: 20px;
            align-items: center;
            justify-content: center;
            gap: 40px;
            width: fit-content;
            border-radius: 0px;
            border: none;
            display: grid;
            grid-template-columns: auto 50%;
            transition: 0.4s ease;

            img {
                width: 150px;
                transition: 0.4s ease;
            }

            p {
                font-size: 14px;
                font-weight: 300;
                margin: 0px;
                color: white;
                transition: 0.4s ease;
            }
        }

        .card:hover {
            p {
                scale: 1.1;

            }

            img,
            p {
                transform: translateX(-40px);
            }
        }

        .federal {
            opacity: 0%;
            background-color: #B02832;

            img{
                opacity: 0%;
                transition: 2s 2s ease;
            }
            p{
                opacity: 0%;
                transition: 2s 2.5s ease;
            }
        }
        .federal.visible{
            animation: fadeDown 2s 1s ease forwards;
            img, p{
                opacity: 100%;
            }
        }
        .exercito {
            opacity: 0%;
            background-color: #6E030A;

            img{
                opacity: 0%;
                transition: 2s 2.5s ease;
            }
            p{
                opacity: 0%;
                transition: 2s 3s ease;
            }
        }
        .exercito.visible{
            animation: fadeDown 2s 1.5s ease forwards;
            img, p{
                opacity: 100%;
            }
        }
        .civil {
            opacity: 0%;
            background-color: #ad1620;

            img{
                opacity: 0%;
                transition: 2s 3s ease;
            }
            p{
                opacity: 0%;
                transition: 2s 3.5s ease;
            }
        }
        .civil.visible{
            animation: fadeDown 2s 2s ease forwards;
            img, p{
                opacity: 100%;
            }
        }
    }
}

@media screen and (max-width: 1440px) {
    .certifications {
        .cards {
            .card {
                gap: 10px;

                img {
                    justify-content: left;
                }
            }
        }
    }
}

@media screen and (max-width: 1024px) {
    .certifications {
        .cards {
            .card {
                justify-content: center;
                text-align: left;
                grid-template-columns: none;
                grid-template-rows: none;

                img {
                    justify-self: center;
                }
            }

        }
    }
}

@media screen and (max-width: 768px) {
    .certifications {
        .cards {
            display: grid;
            gap: 0px;

            .card {
                grid-template-columns: auto auto;
                gap: 50px;
            }

            .card:hover {
                p {
                    scale: 1;

                }

                img,
                p {
                    transform: translateX(0px);
                }
            }
        }
    }
}

@media screen and (max-width: 550px) {
    .certifications {
        padding: 50px 0px !important;

        .cards {
            display: grid;
            justify-content: center;

            .card {
                display: grid;
                justify-self: center;

                img {
                    justify-self: center;
                }
            }

        }
    }
}

@media screen and (max-width: 500px) {
    .certifications {
        h1{
            font-size: 22px !important;
        }
        .cards {
            .card {

                gap: 10px;
                grid-template-columns: none;
                text-align: center;

                img{
                    width: 120px !important;
                }

                p{
                    padding: 0px 17px;
                }
            }
        }
    }
}