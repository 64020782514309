.servicesOne {
  padding: 150px 0px;
  display: grid;
  align-items: center;
  height: 100vh;
  .servicesOneTitle{
    text-align: center;
    opacity: 0%;

    h1{
      padding: 0px 0px 50px 0px;
      font-weight: 600;
      font-size: 30px;
      background: linear-gradient(90deg, #000000 10%, #D30B0B 60%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  .servicesOneTitle.visible{
    animation: slideToUp 2s 0.5s ease forwards;
  }

  .swiper-button-next{
    right: 32%;
  }
  .swiper-button-prev{
    left: 32%;
  }
  .swiper-button-next::after,
  .swiper-button-prev::after{
    display: none !important;
  }
  .swiper-3d .swiper-slide-shadow-left, .swiper-3d .swiper-slide-shadow-right{
    background-image: none;
  }
  .swiper-3d .swiper-slide-shadow{
    background: none;
  }
  .swiper{
    opacity: 0%;
    width: 100%;
    height: fit-content !important;

    .swiper-slide-next{
      .card{
        transform: translateY(-20px);
        opacity: 100%;
        width: 100%;
        h1{
          padding-bottom: 20px;
          font-size: 25px;
        }
        p{
          font-weight: 400;
          width: 450px;
          font-size: 16px;
        }
      }
    }
    .card{
      transform: translateY(20px);
      transition: 0.8s ease-in-out;
      width: 100%;
      opacity: 60%;
      display: grid;
      gap: 0px;
      text-align: center;
      border: none;
      background-color: #00000000;

      img{
        padding: 20px 0px;
        width: 120px;
        justify-self: center;
      }

      h1{
        padding-bottom: 20px;
        font-weight: 600;
        font-size: 18px;
        color: #D30B0B;
      }
      p{
        justify-self: center;
        width: 350px;
        color: #000000;
        font-size: 12px;
        font-weight: 300;
      }
    }
  }
  .swiper.visible{
    animation: slideToUp 2s 0.5s ease forwards;
  }
}

@media screen and (max-width: 1600px) {
  .servicesOne{
    height: auto;
    padding-bottom: 100px !important;

    .swiper-slide{
      .card{
        h1{
          font-size: 22px !important;
        }
        p{
          width: 300px !important;
        }
        img{
          width: 100px !important;
        }
      }
    }
  }
  .servicesOneTitle{
    h1{
      font-size: 25px !important;
    }
  }
  .swiper-button-next{
    right: 30% !important;
  }
  .swiper-button-prev{
    left: 30% !important;
  }
}

@media screen and (max-width: 1000px){
  .servicesOne{
    .servicesOneTitle{
      h1{
        font-size: 25px !important;
      }
    }
    .swiper-button-next{
      right: 1% !important;
    }
    .swiper-button-prev{
      left: 1% !important;
    }
    .swiper{
      width: 90%;

      .card{
        opacity: 100%;
        transform: none;
        transition: none;
        p{
          font-size: 16px !important;
        }
      }
      .swiper-slide-next{
        .card{
          transform: none;
        }
      }
    }
    
  }

}

@media screen and (max-width: 768px) { 
  .servicesOne{
    height: auto;
    .swiper{
      width: 100%;

      .card{
        transform: none !important;
        opacity: 100% !important;
  
        h1{
          font-size: 22px !important;
        }
        p{
          font-size: 18px !important;
          width: 100% !important;
          padding: 0px 50px;
        }
        
      }
    }
    .swiper-button-next{
      right: 20px !important;
    }
    .swiper-button-prev{
      left: 20px !important;
    }
    .swiper-slide-next{
      .card{
        transform: none !important;
      }
    }
    .swiper-button-next, .swiper-button-prev{
      top: 120px;
    }
  }
}
@media screen and (max-width: 500px){
  .servicesOne{
    padding-bottom: 50px !important;
    .servicesOneTitle{
      h1{
        padding-bottom: 20px;
        font-size: 22px !important;
      }
    }
    .swiper{
      .card{
        p{
          width: 100% !important;
          font-size: 16px !important;
        }
      }
    }
  }
}