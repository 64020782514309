.feedback{
    padding: 150px 0px;
    width: 100%;
    display: grid;

    .feedbackTitle{
        width: 100%;
        justify-content: center;
        text-align: center;

        h1{
            position: relative;
            left: -50px;
            font-size: 30px;
            font-weight: 600;
            color: #E20613;
            margin: 0px;
        }
        span{
            position: relative;
            left: 100px;
            font-size: 30px;
            color: black;
            font-weight: 600;
        }
    }
    .swiper1{
        width: 90vw !important;
        padding-top: 50px;
    }
    .swiper2{
        width: 80vw !important;
        .feedbackCard{
        }
    }
    .swiper{
        width: 100vw;
        padding-bottom: 10px;
        
        .swiper-slide{
            .feedbackCard{
                height: 250px;
                border-radius: 20px;
                padding: 20px 20px;
                grid-template-columns: 100%;
                background-color: #a87f7f28;
                display: grid;

                .cardText{
                    p{
                        color: rgb(148, 0, 0);
                        font-size: 16px;
                        font-weight: 600;
                    }
                }
                .cardUser{
                    position: relative;
                    align-self: end;
                    display: flex;
                    align-items: center;

                    .person{
                        width: 50px;
                        height: 50px;
                    }
                    .name{
                        display: grid;
                        padding: 0px 0px 0px 10px;
                        align-items: center;
                        
                        h1{
                            margin: 0px;
                            font-size: 18px;
                            font-weight: 600;
                            color: black;
                        }
                        p{
                            margin: 0px;
                        }
                    }
                    .points{
                        position: relative;
                        margin: 0px 0px 0px auto;
                        width: 120px;
                    }

                }
            }
        }
    }
}

@media screen and (max-width: 1600px){
    .feedbackTitle{
        h1, span{
            font-size: 25px !important;
        }
    }
    .person{
        width: 35px !important;
        height: 35px !important;
    }
    .points{
        width: 90px !important;
    }
}

@media screen and (max-width: 500px){
    .feedbackTitle{
        h1, span{
            left: 0px !important;
        }
    }
}