.moreAbout {
    overflow-x: hidden;
    padding: 200px 0px 0px 0px;
    display: grid;

    .moreTitle {
        justify-self: center;
        text-align: center;

        opacity: 0%;
        transition: 2s 1s ease;
        transform: translateY(100%);

        h1 {
            font-size: 30px;
            font-weight: 600;
            background: linear-gradient(90deg, #000 0%, #D30B0B 40%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }

    .moreTitle.visible {
        transform: translateY(0%);
        opacity: 100%;
    }

    .moreContent {
        padding: 150px 20%;
        display: grid;
        gap: 150px;
        background-image: url('../img/about/about.webp');
        background-repeat: no-repeat;
        background-position: 50% 150px;

        h1 {
            font-size: 25px;
            font-weight: 600;
            color: #D30B0B;
            padding-bottom: 10px;
        }

        p {
            color: #2E2E2E;
            font-size: 18px;
            line-height: 40px;
        }

        .start {
            opacity: 0%;
            transform: translateX(50%);
            transition: 2s 1.5s ease;
            text-align: left;
            justify-self: end;
            width: 60%;
            max-height: 250px;
            min-width: 600px;
        }

        .evolution {
            opacity: 0%;
            transform: translateX(-50%);
            transition: 2s 1.5s ease;
            text-align: right;
            justify-self: start;
            width: 60%;
            max-height: 250px;
            min-width: 600px;
        }

        .legacy {
            opacity: 0%;
            transform: translateX(50%);
            transition: 2s 1.5s ease;
            text-align: left;
            justify-self: end;
            width: 60%;
            max-height: 250px;
            min-width: 600px;
        }

        .start.visible,
        .evolution.visible,
        .legacy.visible {
            opacity: 100%;
            transform: translateX(0%);
        }
    }
}

@media screen and (max-width: 1600px) {
    .moreTitle {
        h1 {
            font-size: 25px !important;
        }
    }

    .moreContent {
        h1 {
            font-size: 22px !important;
        }

        p {
            font-size: 16px !important;
        }
    }
}

@media screen and (max-width: 1400px) {
    .moreContent {
        background-image: url('../img/about/about2.webp') !important;
    }
}

@media screen and (max-width: 1200px) {
    .moreAbout {
        .moreContent {
            padding: 150px 5% 50px;

            .start,
            .evolution,
            .legacy {
                min-width: none;
                max-height: none;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .moreAbout {
        .moreContent {
            gap: 70px;
            padding: 150px 10% 50px;

            .start,
            .evolution,
            .legacy {
                min-width: 0px;
                width: 100%;
            }
        }
    }
}

@media screen and (max-width: 500px) {
    .moreTitle {
        justify-self: left !important;
        padding: 0px 10%;

        h1 {
            font-size: 25px !important;
        }
    }

    .moreContent {
        padding: 100px 10% 30px !important;

        h1 {
            font-size: 22px !important;
        }

        p {
            font-size: 16px !important;
        }
    }
}