.sectionAboutThree {
    background: #E93F3F;
    display: grid;
    overflow: hidden;
    padding: 0rem 0rem 6rem 0rem;

    h2 {
        color: #fff;
        font-weight: 600;
        margin: 0px 0px 20px 0px;
        padding-bottom: 20px;
    }

    p {
        padding-top: 10px;
        color: #fff;
        font-size: 18px;
        width: 90%;

        img {
            margin-right: 10px;
        }
    }

    .item {
        display: grid;
        align-items: center;
        justify-content: left;
        border-radius: 5px;
    }

    .mission {
        opacity: 0%;
        width: 50%;
        position: relative;
        top: 200px;
        padding: 40px 50px 20px 150px;
        background: #e93f3f;
        background-image: linear-gradient(rgba(0, 0, 0, 0.553), rgba(0, 0, 0, 0.701)), url(../img/about/img-gallery-one.webp);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 10% 4%;

    }

    .vision {
        opacity: 0%;
        top: 100px;
        padding: 80px 50px 120px 100px;
        justify-self: right;
        position: relative;
        align-self: center;
        width: 55%;
        background: rgba(14, 0, 0, 0.60);
        background-image: linear-gradient(rgba(0, 0, 0, 0.553), rgba(0, 0, 0, 0.701)), url(../img/about/img-gallery-thirteen.webp);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 30% 80%;
    }

    .value {

        opacity: 0%;
        padding: 80px 50px 100px 130px;
        background-image: linear-gradient(to bottom, #650000dd, #3b0307c8), url("../img/about/img-about-one.webp");
        background-position: 50% 50%;
        background-size: cover;
        background-repeat: no-repeat;
        width: 80%;

        p {
            margin: 0px;
            padding: 10px 0px;
        }
    }

    .mission.visible {
        animation: slideToUp 2s ease forwards;
    }

    .vision.visible {
        animation: slideToUp 2s .4s ease forwards;
    }

    .value.visible {
        animation: slideToUp 2s .8s ease forwards;
    }
}

@media screen and (max-width: 1600px) {
    .sectionAboutThree {
        h2 {
            font-size: 25px !important;
        }

        p {
            font-size: 16px !important;
        }
    }
}

@media screen and (max-width: 1400px) {
    .sectionAboutThree {
        .mission {
            top: 100px;
        }

        .vision {
            padding-right: 10% !important;
        }

        .item {
            p {
                width: 100%;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .sectionAboutThree {
        .mission {
            padding-left: 80px;
            padding-bottom: 20px;
            width: 100%;
        }

        .vision {
            background-color: #720000;
            padding: 50px 50px 80px 80px;
            width: 80%;
            z-index: 10;
        }

        .value {
            position: relative;
            top: 80px !important;
            width: 100%;
            padding-left: 80px;
            z-index: 11;
            padding-bottom: 80px;
            background-color: #da5f5f;
        }
    }
}

@media screen and (max-width: 500px) {
    .sectionAboutThree {
        h2 {
            font-size: 22px !important;
        }

        p {
            font-size: 16px !important;
        }

        .mission {
            padding: 30px 30px 20px 30px;
        }

        .vision {
            text-align: right;
            width: 95%;
            padding: 50px 50px;
        }

        .value {
            padding: 50px 30px;
        }
    }
}