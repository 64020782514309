.budgetOne {
    background-image: url('../img/budget/img-contact.png');
    background-repeat: no-repeat;
    background-position: 85% 200%;
    padding: 200px 150px 50px 150px;
    width: 100%;

    .budgetOneTitle {
        width: 700px;
        display: grid;
        justify-content: left;

        h1 {
            opacity: 0%;
            font-size: 32px;
            font-weight: 600;

            background: linear-gradient(90deg, #D30B0B 0%, #420000 60%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }

        h1.visible {
            animation: slideToDown 2s 0.3s ease forwards;
        }

        p {
            opacity: 0%;
            padding-top: 10px;
            font-size: 22px;
        }

        p.visible {
            animation: slideToDown 2s 0.7s ease forwards;
        }
    }

    .budgetForm {
        padding: 50px 0px;
        width: 700px;
        display: grid;
        justify-self: left;
        gap: 20px;

        input {
            background-color: #00000000;
            width: 100%;
            outline: none;
            transition: 0.5s ease;
        }

        ::placeholder {
            transition: 0.5s ease;
            color: #490000;
        }

        input:focus::placeholder {
            transform: translateX(-40px);
            opacity: 0%;
        }
    }

    .label {
        transition: 0.8s ease;
        width: 100%;
        background-color: #9b0000;
        border: 1px solid #9b0000;
    }

    .input:focus+label {
        background-color: #ff5757;
        border: 1px solid #ff5757;
        width: 10%;
    }

    .form-btn {
        position: relative;
        top: 20px;
        padding: 8px 0px;
        color: white;
        font-weight: 600;
        background-image: linear-gradient(to right, #9b0000, #D30B0B);
        border-radius: 5px;
    }

    .budgetLinks {
        display: grid;
        justify-content: center;

        .links {
            padding: 100px 0px 30px;
            display: flex;
            width: 100%;
            gap: 100px;
            justify-content: center;
            align-items: center;

            .item {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 5px;

                p {
                    color: #D30B0B;
                    font-weight: 600;
                    font-size: 16px;
                    margin: 0px;
                }
            }
        }

        .phone {
            opacity: 0%;
        }

        .phone.visible {
            animation: slideToUp 2s 2s ease forwards;
        }

        .whats {
            opacity: 0%;
        }

        .whats.visible {
            animation: slideToUp 2s 2.5s ease forwards;
        }

        .email {
            opacity: 0%;
        }

        .email.visible {
            animation: slideToUp 2s 3s ease forwards;
        }

        .underline {
            justify-self: center;
            height: 3px;
            width: 0px;
            background-color: #000;
            transition: 1s 4s ease-in-out;
        }

        .underline.visible {
            width: 300px;
        }
    }
}

.budgetOne.visible {
    animation: slideBackground 2s ease forwards;
}

@media screen and (max-width: 1600px) {
    .budgetOne {
        .budgetOneTitle {
            h1 {
                font-size: 25px !important;
            }
        }
    }
}

@media screen and (max-width: 1400px) {
    .budgetOne {
        background-position: 95% 40%;
    }
}

@media screen and (max-width: 1200px) {
    .budgetOne {
        padding: 150px 100px 50px 100px;
    }
}

@media screen and (max-width: 1024px) {
    .budgetOne {
        padding: 150px 100px 50px 100px;
        background-image: none;

        .budgetOneTitle,
        .budgetForm {
            width: 100%;

            h1 {
                font-size: 25px !important;
            }

            p {
                font-size: 20px !important;
            }
        }

        .budgetLinks {
            justify-content: left;

            .links {
                justify-content: left;
                padding: 20px 0px;
                display: grid;
                gap: 20px;

                .item {
                    justify-content: left;
                }
            }

        }
    }
}

@media screen and (max-width: 768px) {
    .budgetOne {
        padding: 150px 50px 50px;
    }
}

@media screen and (max-width: 500px) {
    .budgetOne {
        .budgetOneTitle {
            h1 {
                font-size: 22px !important;
            }

            p {
                font-size: 18px !important;
            }
        }
    }
}