.photoGallery {
    padding: 50px 0px 0px;

    .photoGalleryTitle {
        padding: 50px 100px;
        display: grid;
        justify-content: center;
        text-align: center;

        h1 {
            font-size: 30px;
            font-weight: 600;
            background: linear-gradient(90deg, #440000 38%, #D30B0B 50%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }

        p {
            padding-top: 30px;
            font-size: 18px;
        }
    }

    .swiper {
        max-width: 1600px;
        width: 100%;
        height: 95vh;

        .swiper-pagination-bullet {
            background: #D30B0B !important;
        }
    }

    .card {
        border: none;
    }
}

@media screen and (max-width: 1600px) {
    .photoGallery {
        .photoGalleryTitle {
            h1 {
                font-size: 25px !important;
            }
        }
    }
}

@media screen and (max-width: 1000px) {
    .photoGallery {
        .photoGalleryTitle {
            padding: 50px 50px;
        }

        .swiper {
            max-width: 1600px;
            width: 100%;
            height: 60vh;
        }
    }
}

@media screen and (max-width: 500px) {
    .photoGallery {
        .photoGalleryTitle {
            padding: 50px 0px;

            p {
                font-size: 16px !important;
            }
        }

        .swiper {
            max-width: 1600px;
            width: 100%;
            height: 30vh;
        }
    }
}