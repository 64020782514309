.section-about-four {
    display: grid;
    align-items: center;
    background-image: url(../img/about/background_about.webp);
    background-size: 1500px;
    overflow: hidden;
    background-repeat: no-repeat;
    background-position: 100% 100%;

    .section-four-title{
        opacity: 0%;
        transform: translateX(-10%);
        transition: 2s 0.5s ease;

        padding: 150px 0px 100px 0px;

        h1 {
            background: linear-gradient(90deg, #000 0%, #cf0a0a 40%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-weight: 600;
            text-align: center;
            font-size: 30px;
        }
    }
    .section-four-title.visible{
        opacity: 100%;
        transform: translateX(0%);
    }
    .section-four-content{
        padding: 0px 0px 150px 0px;
        display: flex;
        justify-content: center;
        gap: 150px;

        .item{
            width: 30%;
            justify-content: center;

            h2 {
                color: #F12121;
                text-align: center;
                font-size: 26px;
                font-weight: 700;
                padding-bottom: 30px;
            }
        
            p {
                line-height: 30px;
                text-align: center;
            }
        }

        .itemHOne{
            opacity: 0%;
            transform: translateX(-20%);
            transition: 1.5s 1.5s ease;
        }
        .itemHOne.visible{
            opacity: 100%;
            transform: translateX(0%);
        }
        .itemPOne{
            opacity: 0%;
            transform: translateX(-20%);
            transition: 1.5s 2s ease;
        }
        .itemPOne.visible{
            opacity: 100%;
            transform: translateX(0%);
        }
        .itemHTwo{
            opacity: 0%;
            transform: translateX(20%);
            transition: 1.5s 1.5s ease;
        }
        .itemHTwo.visible{
            opacity: 100%;
            transform: translateX(0%);
        }
        .itemPTwo{
            opacity: 0%;
            transform: translateX(20%);
            transition: 1.5s 2s ease;
        }
        .itemPTwo.visible{
            opacity: 100%;
            transform: translateX(0%);
        }
    }
}
@media screen and (max-width: 1600px) {
    .section-about-four{
        .section-four-title{
            h1{
                font-size: 25px !important;
            }
        }
        .section-four-content{
            h2{
                font-size: 22px !important;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .section-four-content{
        display: grid !important;
        justify-content: center;
        padding-top: 50px !important;
        gap: 80px !important;

        .item{
            padding: 0px 50px;
            width: 100% !important;
        }
    }
}

@media screen and (max-width: 500px){
    .section-about-four{
        .section-four-title{
            padding: 100px 0px 50px 0px;
            h1{
                font-size: 30px !important;
            }
        }
        .section-four-content{
            padding-bottom: 80px !important;
            
            .item{
                h2{
                    font-size: 22px !important ;
                    padding-bottom: 10px;
                }
            }
        }
    }
}