.content {
    background-color: #46A758;
    position: fixed;
    bottom: 2rem;
    right: 2rem;
    z-index: 999;
    padding: .4rem 1rem;
    border-radius: 10px;
}

.text {
    text-shadow: 2px 2px #00000057;
    font-size: 1rem;
    color: #FFF;
    margin: 0;
}
