.servicesTwo {
    overflow: hidden;
    padding: 5% 10% 5% 10%;
    background-image: linear-gradient(rgba(0, 0, 0, 0.553), rgb(111 12 12)), url('../img/about/img-gallery-two.webp');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 0% 100%;
    display: grid;
    position: relative;
    top: 10px;

    h1 {
        opacity: 0%;
        padding: 50px 0px 120px 0px;
        color: white;
        font-size: 35px;
        font-weight: 600;
        justify-self: center;
    }

    h1.visible {
        animation: changeOpacity 2s ease forwards;
    }

    .servicesTwoContent {
        display: grid;
        grid-template-columns: auto auto auto;
        grid-template-rows: auto auto;
        gap: 50px 0px;

        .servicesTwoCard {
            gap: 10px;
            display: grid;
            text-align: center;
            justify-content: center;

            h1 {
                opacity: 100% !important;
                padding: 0px;
                color: white !important;
                font-size: 25px !important;
            }

            p {
                color: white;
                font-size: 18px;
            }
        }

        .stc1 {
            opacity: 0%;
            grid-column-start: 1;
            grid-column-end: 2;
            grid-row-start: 1;
            grid-row-end: 2;
        }

        .stc1.visible {
            animation: slideToRight 2s ease forwards;
        }

        .stc2 {
            opacity: 0%;
            grid-column-start: 3;
            grid-column-end: 4;
            grid-row-start: 1;
            grid-row-end: 2;
        }

        .stc2.visible {
            animation: slideToLeft 2s 0.3s ease forwards;
        }

        .stc3 {
            opacity: 0%;
            grid-column-start: 2;
            grid-column-end: 3;
            grid-row-start: 2;
            grid-row-end: 3;
        }

        .stc3.visible {
            animation: slideToUp 2s .6s ease forwards;
        }
    }
}

@media screen and (max-width: 1600px) {
    .servicesTwo {
        h1 {
            font-size: 25px !important;
        }

        .servicesTwoContent {
            .servicesTwoCard {
                h1 {
                    font-size: 22px !important;
                }

                p {
                    font-size: 16px !important;
                }
            }
        }
    }

}

@media screen and (max-width: 768px) {
    .servicesTwo {
        .servicesTwoContent {
            grid-template-columns: 100%;
            grid-template-rows: auto;

            .stc1,
            .stc2,
            .stc3 {
                grid-column-start: 1;
                grid-column-end: 2;
                grid-row-start: auto;
                grid-row-end: auto;
            }
        }
    }
}

@media screen and (max-width: 500px) {
    .servicesTwo {
        padding-bottom: 15% !important;
        padding-top: 15% !important;

        h1 {
            padding: 20px 0px 100px 0px;
            text-align: center;
            font-size: 25px !important;
        }

        .servicesTwoContent {
            .servicesTwoCard {
                h1 {
                    font-size: 20px !important;
                }

                p {
                    font-size: 16px !important;
                }
            }
        }
    }
}