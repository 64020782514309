.hero {
    padding: 1rem 4rem;
    overflow: hidden;
    // background-image: url(../img/background_hero.png);
    // background-size: cover;
    // background-repeat: no-repeat;
    // background-position: 80% 40%;
}

.content-hero {
    display: flex;
    align-items: center;
    height: 100vh;
    width: 100%;

    .title {
        width: 50%;

        h1 {
            position: relative;
            opacity: 0%;
        
            font-weight: 400;
            font-size: 25px;
            color: #b10808;
        }

        h1.visible{
            animation: slideToRight 2s .5s ease forwards;
        }

        span {
            font-weight: 600;
            color: #790303;
        }

        h4 {
            position: relative;
            opacity: 0%;

            margin-top: 1rem;
            font-weight: 500;
            line-height: 2rem;
            font-size: 16px;
        }
        h4.visible{
            animation: slideToRight 2s .7s ease forwards;
        }
    }
    .title.visible{
        left: 0px;
        opacity: 100%;
    }

}

.itens-title-hero {
    opacity: 0;
    transform: translateY(-20px);
    transition: opacity 2s ease, transform 6s ease;
}

.visible-title-hero {
    opacity: 1;
    transform: translateY(0);
}

.itens-title-hero a {
    text-decoration: none;
    font-weight: 500;
    font-size: 16px;
    color: var(--cor-dark);
}

.itens-title-hero p {
    position: relative;
    z-index: 1000;
}


.content2{
    opacity: 0%;
    position: relative;
    width: 30%;
    display: grid;

    .img-hero {
        display: grid;
        grid-template-areas: img;
        position: relative;
        top: -130px;
        opacity: 100;
        transition: opacity 2s ease, transform 6s ease;
    
        img {
            grid-area: img;
            position: relative;
            top: 120px;
            right: 0px;
            width: 600px;
        }
        .logo{
            z-index: 500;
            position: relative;
            top: 140px;
            align-self: center;
            justify-self: center;
            width: 370px;
        }
        .logo:hover~.img1 {
            scale: 1.3;
            opacity: 10%;
        }
        .logo:hover~.img2{
            scale: 1.3;
            opacity: 10%;
        }
        .img1{
            animation: rota 25s 3s forwards infinite  ease-in-out;
            transition: 0.6s ease-in-out;
        }
        .img2{
            filter: opacity(80%);
            animation: rota2 15s 3s forwards infinite ease-in-out;
            transition: 0.6s ease-in-out;
        }    
        h1{
            font-size: 20px;
            letter-spacing: -1px;
            color: rgb(56, 56, 56);
        }
        .item{
            top: 140px;
            justify-self: center;
            align-self: center;
            grid-area: img;
            height: fit-content;
            width: fit-content;
            text-align: center;
            opacity: 0%;

            h1{
                color: white;
            }
        }
        .item1{
            position: relative;
            transition: 1s ease;

            h1{
                font-size: 22px;
                color:#790303;
            }
        }
        .logo:hover ~ .item1{
            transform: translate(-300px, -200px);
            opacity: 100%;
        }
        .item2{
            position: relative;
            transition: 1s ease;

            h1{
                font-size: 22px;
                color:#790303;
            }
        }
        .logo:hover ~ .item2{
            transform: translate(-400px, -50px);
            opacity: 100%;
        }
        .item3{
            position: relative;
            transition: 1s ease;
        }
        .logo:hover ~ .item3{
            transform: translate(250px, 90px);
            opacity: 100%;
        }
        .item4{
            position: relative;
            transition: 1s ease;

            h1{
                font-size: 22px;
                color:#790303;
            }
        }
        .logo:hover ~ .item4{
            transform: translate(-350px, 100px);
            opacity: 100%;
        }
        .item5{
            position: relative;
            transition: 1s ease;
        }
        .logo:hover ~ .item5{
            transform: translate(-80px, 240px);
            opacity: 100%;
        }
        .item6{
            position: relative;
            transition: 1s ease;
            
            h1{
                font-size: 22px;
                color:#790303;
            }
        }
        .logo:hover ~ .item6{
            transform: translate(50px, -250px);
            opacity: 100%;
        }
        .item7{
            position: relative;
            transition: 1s ease;
        }
        .logo:hover ~ .item7{
            transform: translate(300px, -100px);
            opacity: 100%;
        }
        .item8{
            position: relative;
            transition: 1s ease;
        }
        .logo:hover ~ .item8{
            transform: translate(300px, 0px);
            opacity: 100%;
        }
        .item9{
            position: relative;
            transition: 1s ease;
        }
        .logo:hover ~ .item9{
            transform: translate(160px, 190px);
            opacity: 100%;
        }
    }  
}
.content2.visible{
    animation: slideToLeft 3s .5s ease forwards;
}


/* -----------MEDIA------------------- */

@media screen and (min-width: 1800px){
    .title{
        h4{
            font-size: 18px !important;
        }
    }

    .logo:hover ~ .item4{
        transform: translate(-350px, 150px);
        opacity: 100%;
    }

}

@media screen and (max-width: 1600px) {
    .title{
        h1{
            font-size: 24px !important;
        }
        h4{
            font-size: 18px !important;
        }
    }
    .img-hero{
        left: 100px;
    }
    .img1, .img2{
        width: 100% !important;
    }
    .logo{
        width: 70% !important;
    }
    .logo:hover ~ .item4{
        transform: translate(-400px, 80px) !important;
        opacity: 100%;
    }
    .logo:hover ~ .item6{
        transform: translate(0px, -250px) !important;
        opacity: 100%;
    }

}

@media screen and (max-width: 1400px) {
    .item{
        h1{
            font-size: 18px !important;
        }
    }
    .logo:hover ~ .item1{
        transform: translate(-200px, -170px) !important;
        opacity: 100%;
    }
    .logo:hover ~ .item2{
        transform: translate(-300px, -50px) !important;
        opacity: 100%;
    }
    .logo:hover ~ .item3{
        transform: translate(200px, 90px) !important;
        opacity: 100%;
    }
    .logo:hover ~ .item4{
        transform: translate(-220px, 140px) !important;
        opacity: 100%;
    }
    .item4{
        h1{
            color: white !important;
        }
    }
    .logo:hover ~ .item5{
        transform: translate(-80px, 200px) !important;
        opacity: 100%;
    }
    .logo:hover ~ .item6{
        transform: translate(180px, -180px) !important;
        opacity: 100%;
    }
    .item6{
        h1{
            color: white !important;
        }
    }
    .logo:hover ~ .item7{
        transform: translate(250px, -100px) !important;
        opacity: 100%;
    }
    .logo:hover ~ .item8{
        transform: translate(250px, 0px) !important;
        opacity: 100%;
    }
    .logo:hover ~ .item9{
        transform: translate(120px, 160px) !important;
        opacity: 100%;
    }

}

@media screen and (max-width: 1300px) {
        .logo:hover ~ .item6{
            transform: translate(50px, -200px) !important;
            opacity: 100%;
            h1{
                color: #790303 !important;
            }
        }

        .logo:hover ~ .item4{
            transform: translate(-300px, 100px) !important;
            opacity: 100%;
        }
        .item4{
            h1{
                color: #790303 !important;
            }
        }
}

@media screen and (max-width: 1200px) {
    .logo:hover ~ .item6{
        transform: translate(150px, -180px) !important;
        opacity: 100%;
        h1{
            color: #ffffff !important;
        }
    }
    .item4{
        h1{
            color: white !important; 
        }
    }
    .logo:hover ~ .item4{
        transform: translate(-250px, 140px) !important;
        opacity: 100%;
    }
}

@media screen and (max-width: 1100px) {
    .img-hero{
        left: 0px !important;
    }
    .item{
        h1{
            font-size: 18px !important;
        }
    }
    .logo:hover ~ .item1{
        transform: translate(-200px, -190px) !important;
        opacity: 100%;
    }
    .logo:hover ~ .item2{
        transform: translate(0px, -250px) !important;
        opacity: 100%;
    }
    .logo:hover ~ .item3{
        transform: translate(250px, 70px) !important;
        opacity: 100%;
    }
    .logo:hover ~ .item4{
        transform: translate(170px, 130px) !important;
        opacity: 100%;
        h1{
            color: white;
        }
    }
    .logo:hover ~ .item5{
        transform: translate(-100px, 220px) !important;
        opacity: 100%;
    }
    .logo:hover ~ .item6{
        transform: translate(230px, -180px) !important;
        opacity: 100%;
        h1{
            color: white;
        }
    }
    .logo:hover ~ .item7{
        transform: translate(270px, -100px) !important;
        opacity: 100%;
    }
    .logo:hover ~ .item8{
        transform: translate(300px, 0px) !important;
        opacity: 100%;
    }
    .logo:hover ~ .item9{
        transform: translate(80px, 180px) !important;
        opacity: 100%;
    }
    .img-hero{
        left: 100px;
    }
    .img1, .img2{
        width: 100% !important;
    }
    .logo{
        width: 70% !important;
    }
}

@media screen and (max-width: 1000px){
    .content-hero{
        .title{
            position: relative;
            top: -100px;        
        }
        .content2{
            top: -100px;
            left: 0px;
            justify-self: center;
            
            .item h1{
                font-size: 16px !important;
            }
            .item1{
                transform: translate(-200px, -190px) !important;
                opacity: 100%;
            }
            .item2{
                transform: translate(40px, -240px) !important;
                opacity: 100%;
            }
            .item3{
                transform: translate(230px, 35px) !important;
                opacity: 100%;
            }
            .item4{
                transform: translate(170px, 100px) !important;
                opacity: 100%;
                h1{
                    color: white;
                }
            }
            .item5{
                transform: translate(-100px, 190px) !important;
                opacity: 100%;
            }
            .item6{
                transform: translate(200px, -180px) !important;
                opacity: 100%;
                h1{
                    color: #790303 !important;
                }
            }
            .item7{
                transform: translate(240px, -100px) !important;
                opacity: 100%;
            }
            .item8{
                transform: translate(250px, -30px) !important;
                opacity: 100%;
            }
            .item9{
                transform: translate(80px, 150px) !important;
                opacity: 100%;
            }
        }
    }

}


@media screen and (max-width: 800px) {
    .content-hero{    
        .title {
            width: 100%;
            padding-top: 10rem;
        }
        .content2{
            width: 100%;
            top: 0px !important;
            left: 0px !important;
            justify-self: left !important;
            
            .logo{
                top: 125px !important;
            }
            .item{
                display: none;
            }
        }
    }
}

@media screen and (max-width: 500px) {
    .hero {
        overflow-x: hidden;
        padding: 1rem 2rem;

        .content-hero{
            display: grid;
            
            .title{
                padding: 250px 0px 0px 0px ;

                h1{
                    font-size: 22px;
                }
            }
            .content2{
                top: -80px !important;
            }
        }
        .content2{
            .img-hero{
                img{
                    grid-area: 'img';
                }
                grid-template-areas: 'img';
                grid-template-columns: 100%;
     
                .logo{
                    width: 200px !important;
                    justify-self: center;
                }
                .img1{
                    width: 300px !important;
                    justify-self: center;
                }
                .img2{
                    width: 300px !important;
                    justify-self: center;
                }
            }
        }
    }
}