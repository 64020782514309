@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600&display=swap');

* {
    scroll-behavior: smooth !important;
    padding: 0;
    text-decoration: none;
    font-family: 'Montserrat', sans-serif;
    border: hidden;
    box-sizing: border-box;
}

body {
    background-image: linear-gradient(to right, #fff, #58000017);

}

a {
    text-decoration: none;
}

::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-thumb {
    background-color: var(--cor-secondary);
}

::-webkit-scrollbar-track {
    background-color: #751313;
}

:root {
    --cor-primary: #E20613;
    --cor-secondary: #AD0F0A;
    --cor-dark: #232323;
    --cor-white: #fff;
}

.background-home {
    background-image: url(../img/home/background-home.webp);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 3px 1%;
}

.align-center {
    display: flex;
    justify-content: center;
}


@media screen and (max-width: 1200px) {
    .background-home {
        background-image: url(../img/home/background-home.webp);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 60% 60%;
    }
}