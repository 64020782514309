.aboutSix{
    padding: 150px;
    display: grid;
    gap: 50px;

    background-image: url(../img/about/video-bg.webp);
    background-repeat: no-repeat;
    background-size: cover;

    .aboutSixTitle{
        h1{
            font-size: 25px !important;
            color: var(--cor-primary);
            font-weight: 600;
        }
        span{
            color: var(--cor-secondary);
        }
        p{
            font-size: 18px !important;
            color: black;
        }
    }
    .aboutSixVideo{
        justify-self: center;
        padding: 10px;
        background-color: #830200;
        border-radius: 20px;
        height: fit-content;

        video{
            border-radius: 10px;
            width: 800px;
            height: auto;
            margin: 0px;
        }
    }
}

@media screen and (max-width: 1200px){
    .aboutSix{
        padding: 5%;
        justify-content: center;

        .aboutSixVideo{
            width: 80%;
            video{
                width: 100%;
            }
        }
    }
}

@media screen and (max-width: 768px){
    .aboutSix{
        padding: 10%;

        .aboutSixTitle{
            h1{
                font-size: 22px !important;
            }
        }
        .aboutSixVideo{
            width: 100%;
            video{
                width: 100%;
            }
        }
    }
}