.nav-container {
    display: flex;
    align-items: center;
    width: 100%;

    .homeLink a {
        color: var(--cor-primary);
        transition: 0.4s ease;
        border-radius: 5px;
        border: 1px solid transparent;
        position: relative;
    }

    .aboutLink a {
        color: var(--cor-primary);
        transition: 0.4s ease;
        border-radius: 5px;
        border: 1px solid transparent;
        position: relative;
    }

    .servicesLink a {
        color: var(--cor-primary);
        transition: 0.4s ease;
        border-radius: 5px;
        border: 1px solid transparent;
        position: relative;
    }

    .navLogo:hover {
        opacity: 0%;
        transform: translateX(-100%);
        transition: 2s ease;
    }

    .navLogo.visible {
        transform: translateX(0%);
        opacity: 100%;
    }

    .nav-item{
        padding: 0px ;
    }
    .navbar-nav{
        gap: 10px;
        align-items: center;
    }
    .homeLink {
        opacity: 0%;
        transform: translateY(-200%);
        transition: 1s ease;

        a{
            display: block;
            height: 100%;
            width: 100%;
            padding: 5px 15px;
        }
    }

    .aboutLink {
        opacity: 0%;
        transform: translateY(-200%);
        transition: 1.4s ease;

        a{
            display: block;
            height: 100%;
            width: 100%;
            padding: 5px 15px;
        }
    }

    .servicesLink {
        opacity: 0%;
        transform: translateY(-200%);
        transition: 1.8s ease;

        a{
            display: block;
            height: 100%;
            width: 100%;
            padding: 5px 15px;
        }
    }

    .workLink {
        opacity: 0%;
        transform: translateY(-200%);
        transition: 2.2s ease;
    }

    .budgetLink {
        opacity: 0%;
        transform: translateY(-200%);
        transition: 2.6s ease;
    }

    .clientLink {
        opacity: 0%;
        transform: translateY(-200%);
        transition: 3s ease;
    }

    .homeLink.visible {
        opacity: 100%;
        transform: translateY(0%);
    }

    .aboutLink.visible {
        opacity: 100%;
        transform: translateY(0%);
    }

    .servicesLink.visible {
        opacity: 100%;
        transform: translateY(0%);
    }

    .budgetLink.visible {
        opacity: 100%;
        transform: translateY(0%);
    }

    .clientLink.visible {
        opacity: 100%;
        transform: translateY(0%);
    }


    .flex-content-nav {
        display: flex;
        justify-content: space-between;
        width: 100%;

        .btn-danger {
            color: var(--cor-white);
        }
    }

    .navbar-toggler {
        float: right;
    }

    .offcanvas-body {
        display: flex;
        padding: 10px;
    }

    .flex-button-nav {
        display: flex;
    }

    .btn-group {
        padding: 0px 16px;
        transition: 0.2;
    }

    .btn-group .btn {
        padding: 8px;
        border: 1px solid #ffffff00;
    }

    .btn-group:hover .btn {
        color: var(--cor-secondary);
        border: 1px solid var(--cor-secondary);
        border-radius: 5px;
    }
}

.navbar {
    position: fixed;
    top: 0;
    width: 100%;

    z-index: 9999;
}

.navbar-nav .nav-link.active,
.navbar-nav .nav-item,
.navbar-nav .nav-link.show,
.btn {
    color: var(--cor-primary);
    transition: 0.4s ease;
    border-radius: 5px;
    border: 1px solid transparent;

    &:hover,
    &:active {
        color: var(--cor-secondary);
        border: 1px solid var(--cor-secondary);
        border-radius: 5px;
        transform: translateX(10px);
    }
}

.nav-link {
    color: var(--cor-primary);
    display: grid;
    align-items: center;
}

.nav-container .btn-group .btn {
    background-color: #ffffff00 !important;
}

.btnClient {
    border: 1px solid var(--cor-secondary);
}

.btnClient:hover {
    background-color: #ffffff00;
}

.navbar-toggler {
    border: var(--cui-border-width) solid #ad8080;
}

.navbar-toggler-icon {
    background-image: url(../icon/menu.png) !important;
}


@media screen and (max-width: 1199px) {
    .flex-content-nav {
        flex-direction: column;
        justify-content: flex-start !important;
    }
    .nav-container .flex-button-nav{
        margin-top: 30px;
        gap: 10px;
    }
    .nav-container .navbar-nav{
        padding-left: 12px;
        align-items: start;
    }
    .nav-link.active{
        padding: 8px 10px;
    }
    .budgetLink, .clientLink{
        margin-left: 18px;
    }
    .flex-button-nav {
        margin-top: 10px;
        flex-direction: column;
        gap: 20px;
    }
}

.scrolled {
    background-color: #ffffff;
    transition: background-color 0.8s ease;
}

@media screen and (max-width: 500px) {
    .px-5 {
        padding: 1rem 2rem !important;
    }
}