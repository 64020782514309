.sectionOne {
    overflow-x: hidden;

    height: 100%;

    h1 {
        font-size: 25px;
        text-align: center;
        color: var(--cor-white);
        padding-top: 10rem;
    }

    .container-section-one {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-areas:
            "one one two two"
            "three three two two";


        padding-top: 8rem;
        padding-bottom: 6rem;
        margin: 0;

        .contentOne,
        .contentTwo {
            opacity: 0;

            &.animate {
                animation: fadeInRight 2s ease-in-out forwards;
            }
        }


        .contentThree {
            opacity: 0;

            &.animate {
                animation: fadeIn 2s ease-in-out forwards;
            }
        }


        .contentOne {
            grid-area: one;

            padding: 1rem 2rem;
            height: 50vh;
            width: 115%;

            border-radius: 8px;

            color: var(--cor-white);
            box-shadow: 0px 20px 60px 0px rgba(0, 0, 0, 0.07);
            transform: translateX(-100%);

            display: flex;
            align-items: center;

            animation-delay: 0.5s;

            h2 {
                font-size: 20px;
            }

            p {
                font-size: 16px;
            }
        }

        .contentTwo {
            grid-area: two;

            position: relative;
            top: 40px;
            z-index: 9998;

            height: 70vh;

            padding: 1rem 3rem;

            color: var(--cor-white);

            animation-delay: s;

            transform: translateY(-100%);

            display: flex;
            align-items: center;

            background-image: linear-gradient(rgba(0, 0, 0, 0.553), rgba(0, 0, 0, 0.701)), url(../img/home/img_home.webp);
            box-shadow: 0px 20px 60px 0px rgba(0, 0, 0, 0.07);

            background-size: cover;
            background-repeat: no-repeat;
            background-position: 100% 100%;

            &.animate {
                opacity: 1;
                transform: translateX(0);
            }

            h2 {
                font-size: 20px;
            }

            p {
                font-size: 15px;
            }
        }

        .contentThree {
            grid-area: three;

            height: 50vh;
            width: 145%;

            border-radius: 0px 0px 15px 0px;
            box-shadow: 0px 20px 60px 0px rgba(0, 0, 0, 0.07);

            padding: 1rem 2rem;

            background: #E93F3F;
            color: var(--cor-white);

            transform: translateX(-100%);

            animation-delay: 0.1s;

            display: flex;
            align-items: center;


            h2 {
                font-size: 20px;
            }

            p {
                font-size: 16px;
            }
        }
    }

    .texto1,
    .texto2 {
        width: 80%;
    }

    .texto3 {
        width: 70%;
    }
}

@media screen and (max-width: 1600px) {
    .sectionOne {
        h1 {
            font-size: 22px !important;
        }
    }
}

@media screen and (max-width: 1000px) {
    .sectionOne h1 {
        padding: 0px;
    }
}

@media screen and (max-width: 800px) {
    .sectionOne {
        h1 {
            padding: 0px 30px;
        }
    }

    .sectionOne .container-section-one {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-template-areas:
            "one "
            "two "
            "three ";
    }

    .sectionOne .container-section-one .contentTwo {
        position: relative;
        top: 0px;
    }
}

@media screen and (max-width: 500px) {
    .sectionOne h1 {
        text-align: left;
        margin: 60px 0px 0px 0px;
        font-size: 22px !important
    }

    .sectionOne p {
        font-size: 15px !important;
    }

    .sectionOne h2 {
        font-size: 18px !important;
        margin: 0px 0px 20px 0px;
    }
}