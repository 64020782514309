.map {
    overflow: hidden;
    height: fit-content;
    display: grid;
    justify-content: center;
    grid-template-columns: 50% 50%;

    background-image: linear-gradient(to top, #74030bdd, #84060ec8), url("../img/about/img-gallery-four.webp");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;

    position: relative;
    z-index: 2;

    .mapTitle {
        padding: 50px 0px 0px 0px;
        display: flex;
        position: relative;

        img {
            opacity: 0%;
            align-self: center;
            position: relative;
            top: -30px;
            left: 100px;
            height: 200px;
            width: 150px;
            z-index: 50;
            filter: brightness(65%);
            transform: translateX(-300px);
            transition: 2s 1s ease;
        }

        img.visible {
            transform: translateX(0px);
            opacity: 30%;
        }

        .mapTitle2 {
            opacity: 0%;
            transform: translateX(-200px);
            transition: 2s 0.5s;
            z-index: 100;
            align-self: center;
            padding-bottom: 40px;

            h1 {
                font-size: 25px;
                text-align: center;
                color: var(--cor-white);
            }

            h2 {
                font-size: 18px;
                text-align: center;
                color: var(--cor-white);
            }
        }

        .mapTitle2.visible {
            opacity: 100%;
            transform: translateX(0%);
        }
    }

    .mapContent {
        display: grid;
        grid-template-areas: 'map';
        z-index: 100;
        justify-content: center;
        align-items: center;
        position: relative;
        right: 100px;

        .itemMap {
            transition: 2s 2s ease;
            opacity: 0%;
            z-index: 0;
            position: absolute;
            align-self: center;
            height: 300px;
            width: 250px;
            left: 200px;
            top: 330px;
        }

        .itemMap.visible {
            opacity: 100%;
        }

        .brasil {
            grid-area: map;
            position: relative;
            right: 100px;
            width: 1000px;
            opacity: 0%;
            transition: 2s 1s ease;
        }

        .brasil.visible {
            opacity: 100%;
        }

        .point {
            scale: 0 !important;
            cursor: pointer;
            position: relative;
            align-self: center;
            justify-self: center;
            grid-area: map;
            width: fit-content !important;

            .card {
                display: none;

                h1 {
                    font-size: 14px !important;
                    margin: 0px;
                    color: var(--cor-primary);
                    font-weight: 600;
                }
            }

            img {
                z-index: 1 !important;
            }
        }

        .point:hover {
            .card {
                display: grid;
                width: 120px;
                justify-content: center;
                position: absolute;
                bottom: 5px;
                right: 5px;
                padding: 15px 0px;
                z-index: 500;
                border: none;
                background-color: rgb(243, 243, 243);
                text-align: center;
                transition: .5s ease;
            }
        }

        .point1 {
            z-index: 1;
            scale: 1.1;
            transition: 1s 2s ease;
            top: -235px;
            left: 230px;
        }

        .point2 {
            z-index: 2;
            transition: 1s 2.1s ease;
            top: -220px;
            left: 270px;
        }

        .point3 {
            z-index: 3;
            transition: 1s 2.2s ease;
            top: -180px;
            left: 295px;
        }

        .point4 {
            z-index: 5;
            transition: 1s 2.3s ease;
            top: -152px;
            left: 290px;
        }

        .point5 {
            z-index: 4;
            transition: 1s 2.4s ease;
            top: -150px;
            left: 270px;
        }

        .point6 {
            z-index: 9;
            transition: 1s 2.5s ease;
            top: -125px;
            left: 290px;
        }

        .point7 {
            z-index: 10;
            transition: 1s 2.6s ease;
            top: -120px;
            left: 275px;
        }

        .point8 {
            z-index: 8;
            transition: 1s 2.7s ease;
            top: -120px;
            left: 235px;
        }

        .point9 {
            z-index: 7;
            transition: 1s 2.8s ease;
            scale: 1.1;
            top: -115px;
            left: 200px;
        }

        .point10 {
            transition: 1s 2.9s ease;
            z-index: 6;
            top: -120px;
            left: 170px;
        }

        .point11 {
            transition: 1s 3s ease;
            z-index: 13;
            top: -95px;
            left: 305px;
        }

        .point12 {
            transition: 1s 3.1s ease;
            scale: 1.1;
            z-index: 14;
            top: -70px;
            left: 315px;
        }

        .point13 {
            transition: 1s 3.2s ease;
            top: -80px;
            z-index: 12;
            left: 290px;
        }

        .point14 {
            transition: 1s 3.3s ease;
            top: -70px;
            z-index: 11;
            left: 220px;
        }

        .point15 {
            transition: 1s 3.4s ease;
            top: -20px;
            scale: 1.1;
            z-index: 15;
            left: 220px;
        }

        .point16 {
            transition: 1s 3.5s ease;
            top: 40px;
            left: 300px;
        }

        .point17 {
            transition: 1s 3.6s ease;
            top: 40px;
            left: 320px;
            scale: 1.1;
        }

        .point18 {
            transition: 1s 3.6s ease;
            top: -270px;
            left: 100px;
            scale: 1.1;
        }

        .point.visible {
            scale: 1 !important;
        }
    }
}

/* ------------MEDIAS------------ */



@media screen and (max-width: 1200px) {
    .map {
        justify-content: left;

        .mapContent {
            right: 160px;
        }

        .mapTitle {
            right: 100px;
        }
    }

}

@media screen and (max-width: 700px) {
    .map {
        .mapTitle {
            right: 15px;
        }

        .mapContent {
            .point {
                .card {
                    bottom: 5px;
                    left: 5px;
                }
            }

            .point1 {
                z-index: 2;
            }

            .point2 {
                z-index: 1;
            }

            .point3 {
                z-index: 3;
            }

            .point4 {
                z-index: 4;
            }

            .point5 {
                z-index: 5;
            }

            .point6 {
                z-index: 6;
            }

            .point7 {
                z-index: 7;
            }

            .point8 {
                z-index: 8;
            }

            .point9 {
                z-index: 9;
            }

            .point10 {
                z-index: 10;
            }

            .point11 {
                z-index: 12;
            }

            .point12 {
                z-index: 13;
            }

            .point13 {
                z-index: 14;
            }

            .point14 {
                z-index: 15;
            }

            .point15 {
                z-index: 11;
            }

            .point16 {
                z-index: 2;
            }

            .point17 {
                z-index: 1;
            }

            .point18 {
                z-index: 2;
            }
        }
    }
}

@media screen and (max-width: 500px) {
    .itemMap {
        display: none;
    }

    .map {
        grid-template-columns: 40% 60%;

        .mapTitle2 {
            left: -100px !important;
            width: 120px !important;

            h1,
            h2 {
                text-align: left !important;
            }

            h1 {
                font-size: 17px !important;
            }

            h2 {
                font-size: 14px !important;
            }

        }
    }
}