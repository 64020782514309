.instaFeed{
    height: fit-content;
    width: 100%;
    padding: 120px 0px 50px 0px;
    display: grid;
    justify-content: center;
    height: fit-content;

    h1{
        opacity: 0%;
        padding: 0px 16px;
        text-align: center;
        font-size: 25px;
        font-weight: 600;
        color: var(--cor-primary);
    }
    h1.visible{
        animation: changeOpacity 2s ease forwards;
    }
    h2{
        opacity: 0%;
        padding: 0px 16px;
        text-align: center;
        font-size: 25px;
        font-weight: 600;
        color: #790505;
    }
    h2.visible{
        animation: changeOpacity 2s 0.2s ease forwards;
    }

    a{
        text-decoration: none;
    }

    .cards{
        opacity: 0%;
        display: grid;
        padding: 60px 150px 0px 150px;
        grid-template-columns: auto auto auto auto;
        grid-template-rows: auto auto;
        width: 100%;
        max-width: 1400px;
        gap: 8px;
    }
    .cards.visible{
        animation: halfToRight 2.5s 1s ease forwards;
    }

    .card1{
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 1;
        grid-row-end: 2;
    }
    .card2{
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 2;
        grid-row-end: 3;
    }
    .card3{
        grid-column-start: 2;
        grid-column-end: 4;
        grid-row-start: 1;
        grid-row-end: 3;
    }
    .card4{
        grid-column-start: 4;
        grid-column-end: 5;
        grid-row-start: 1;
        grid-row-end: 2;
    }
    .card5{
        grid-column-start: 4;
        grid-column-end: 5;
        grid-row-start: 2;
        grid-row-end: 3;
    }

    .card-media {
        box-shadow: 0 8px 32px 0 #790505c4;
        justify-content: center;
        display: grid;
        grid-template-areas: 'card';
        height: 100%;
        overflow: hidden;
        border-radius: 10px;
        align-items: center;
        width: auto;
        transition: 0.4s ease;
    }
    
    .media-text {
        transition: 0.6s ease-in-out;
        align-items: center;
        text-align: center;
        overflow: hidden;
        display: grid;
        /*     display: -webkit-box;
     */
        grid-area: card;
        box-sizing: border-box;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 4;
        height: 100%;
        min-width: 0px;
        max-width: 100%;
        line-height: 1.3;
    }
    
    .media-text p {
        font-size: 14px;
        font-weight: 500;
        overflow: hidden;
        box-sizing: border-box;
        margin: 0px;
        min-width: 0px;
        display: -webkit-box;
        max-width: 100%;
        text-overflow: ellipsis;
        white-space: normal;
        overflow-wrap: normal;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 6;
        line-height: 1.3;
        width: 80%;
        justify-self: center;
        max-height: 148px;
        z-index: 5;
        color: white;
        transition: 0.6s ease-in-out;
    }
    
    .text-video {
        display: none;
    }
    
    .card-media img,
    video {
        z-index: 10;
        -moz-transition: all 0.5s;
        -webkit-transition: all 0.5s;
        display: grid;
        grid-area: card;
        border-radius: 10px;
        width: 100%;
        height: 100%;
        object-fit: cover;
        background-size: cover;
    }
    
    .card-media:hover .media-text {
        background-color: #790505d5;
        z-index: 10;
    }
    
    .card-media:hover .media-text p {
        transition: 0.5s ease-in-out;
        z-index: 11;
        opacity: 100;
    }
    
    .card-media:hover.card-media img {
        -moz-transform: scale(1.2);
        -webkit-transform: scale(1.2);
        transform: scale(1.1);
        filter: blur(1px);
    }
    @media screen and (max-width: 1200px){
        .cards{
            padding: 50px 100px 0px 100px;
        }       
    }
    @media screen and (max-width: 1000px){
        .cards{
            padding: 50px 50px 0px 50px;
        }       
    }
    @media screen and (max-width: 768px) {
        .card-media:hover .media-text {
            background-color: none;
            z-index: 0;
        }
        
        .card-media:hover .media-text p {
            transition: none;
            z-index: 0;
            opacity: 0;
        }
        
        .card-media:hover.card-media img {
            -moz-transform: none;
            -webkit-transform: none;
            transform: none;
            filter: none;
        }
        .cards{
            display: grid;
            grid-template-columns: auto auto;
            grid-template-rows: auto auto auto auto;
        }
        .card1{
            grid-column-start: 1;
            grid-column-end: 3;
            grid-row-start: 1;
            grid-row-end: 3;
        }
        .card2{
            grid-column-start: 1;
            grid-column-end: 2;
            grid-row-start: 3;
            grid-row-end: 4;
        }
        .card3{
            grid-column-start: 2;
            grid-column-end: 3;
            grid-row-start: 3;
            grid-row-end: 4;
        }
        .card4{
            grid-column-start: 1;
            grid-column-end: 2;
            grid-row-start: 4;
            grid-row-end: 5;
        }
        .card5{
            grid-column-start: 2;
            grid-column-end: 3;
            grid-row-start: 4;
            grid-row-end: 5;
        }
        .card-media:hover .media-text {
            display: none;
        }
    
        .card-media:hover.card-media img {
            -moz-transform: scale(1);
            -webkit-transform: scale(1);
            transform: scale(1);
            filter: none;
        }
    }
}
@media screen and (max-width: 500px) {
    .instaFeed{
        h1, h2{
            font-size: 22px !important;
        }
        
    }
}




