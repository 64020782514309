.social-media {
    cursor: pointer;
    opacity: 0%;
    position: fixed;
    z-index: 9999;

    bottom: 50px;
    left: 10px;
    margin: 10px;

    width: fit-content;
    height: fit-content;
    padding: 4px;

    border-radius: 15px;    

    .items{
        z-index: 100;
        width: 50px;
        height: 50px;
        display: grid;
        border-radius: 50px;
        background-color: var(--cor-secondary);
        justify-content: center;
        align-items: center;
        transition: .4s ease;
        
        .link{
            z-index: 100;
            justify-self: center;
            align-self: center;
        }
        img{
            width: 30px;
            position: relative;
            margin-right: 6px;
            align-self: center;
            justify-self: center;
            transition: .4s ease;
        }
        .item{
            position: relative;
            left: 200px;
            width: fit-content;
            height: fit-content;
            justify-self: center;
            align-self: center;
            padding: 2px;
            border-radius: 12px;
            display: none;
            z-index: 0;
        
            a{
                padding: 2px;
                border-radius: 10px;
            }
        
            img {
                margin: 1px;
                width: 25px;
                height: 25px;
                filter: brightness(100);
            }
        }
        .face, .whats, .insta{
            opacity: 0%;
        }
    }
    .items:hover{
        display: flex;
        border-radius: 50px;
        width: 200px;

        .link{
            opacity: 10%;
        }
        .whats{
            animation: slideToRight2 .8s .4s ease forwards;
            display: block;
        }
        .insta{
            animation: slideToRight2 .8s .2s ease forwards;
            display: block;
        }
        .face{
            animation: slideToRight2 .8s ease forwards;
            display: block;
        }
    }
}

.social-media.visible{
    animation: slideToRight 1s 1.8s ease forwards;
}


@media screen and (max-width: 425px){
    .social-media{
        left: 5px;
        bottom: 20px;
    }
}