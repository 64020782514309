.servicesThree{
    overflow-x: hidden;
    background-image: linear-gradient(to bottom, #680000 0%, #ffffff00 20% 100%);
    padding: 400px 200px 100px 200px;
    .servicesThreeTitle{
        padding-bottom: 50px;
        h1{
            opacity: 0%;
            font-size: 35px;
            font-weight: 600;
            color: Black;
        }
        h1.visible{
            animation: halfToRight 2s ease forwards;
        }
        span{
            font-weight: 600;
            color: var(--cor-primary);
        }
        p{
            opacity: 0%;
            font-size: 22px;
            color: #520000;
            font-weight: 500;
        }
        p.visible{
            animation: halfToRight 2s 0.2s ease forwards;
        }
    }
    .servicesThreeContent{
        display: grid;
        justify-content: space-between;
        grid-template-columns: 50% 50%;

        .contentText{
            padding-top: 20px;
            display: grid;
            align-items: center;

            .content1, .content2{
                opacity: 0%;
                width: 100%;
                
                h1{
                    font-size: 25px !important;
                    color: var(--cor-secondary);
                    letter-spacing: -1px;
                    font-weight: 600;
                    padding: 0px;
                    margin: 0px;
                }
                h6{
                    width: 90%;
                    margin: 0px;
                    padding: 20px 0px;
                    color: rgb(78, 0, 0);

                }
                strong{
                    color: #680000;
                }
                p{
                    padding: 40px 150px 0px 0px;
                    font-size: 18px !important;
                    color: rgb(78, 0, 0);
                }
            }
            .content1.visible{
                animation: slideToRight 2s 0.5s ease forwards;
            }
            .content2{
                padding-bottom: 100px;
            }
            .content2.visible{
                animation: slideToRight 2s 0.8s ease forwards;
            }
        }
        .contentImg{
            opacity: 0%;
            position: relative;
            bottom: 50px;
            width: 100%;
            display: grid;
            grid-template-areas: 'img';

            img{
                grid-area: img;
                align-self: center;
                justify-self: center;
            }
            .img1{
                opacity: 30%;
                z-index: 10;
                animation: fadeOutInOut infinite 10s 1.5s;
            }
            .img2{
                opacity: 0%;
                z-index: 11;
                animation: fadeOutInOut2 infinite linear backwards 8s 1.8s;
            }
            .img3{
                z-index: 12;
            }
        }
        .contentImg.visible{
            animation: halfToLeft 3s .5s ease forwards;
        }
    }
}

@media screen and (max-width: 1600px){
    .servicesThree{
        padding: 400px 100px 100px !important;
        .servicesThreeTitle{
            h1{
                font-size: 25px !important;
            }
            p{
                font-size: 20px !important;
            }
        }
        .servicesThreeContent{
            .contentText{
                .content1, .content2{
                    h1{
                        font-size: 22px !important;
                    }
                    p{
                        font-size: 16px !important;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1400px){
    .servicesThree{
        .servicesThreeContent{
            .contentText{
                .content1, .content2{
                    p{
                        padding: 20px 50px 0px 0px !important;
                    }
                }
            }
            .contentImg{
                img{
                    width: 500px;
                }
            }
        }
    }
}

@media screen and (max-width: 1200px){
    .servicesThree{
        padding: 300px 50px 0px 50px !important;
        .servicesThreeContent{
            .contentImg{
                img{
                    width: 100%;
                }
            }
        }
    }
}

@media screen and (max-width: 1000px){
    .servicesThree{
        .servicesThreeTitle{
            h1{
                font-size: 28px !important;
            }
            p{
                font-size: 18px !important;
            }
        }
        .servicesThreeContent{
            .contentText{
                .content1, .content2{
                    h1{
                        font-size: 22px !important;
                    }
                    p{
                        font-size: 16px !important;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 768px){
    .servicesThree{
        .servicesThreeTitle{
            padding-bottom: 10px;
        }
        .servicesThreeContent{
            grid-template-columns: 100%;
            gap: 20px;

            .contentText{
                gap: 20px;
                .content2{
                    padding: 0px;
                }
            }
            .contentImg{
                justify-self: center;
                width: 60%;
                bottom: 0px;
            }
        }
    }
}

@media screen and (max-width: 500px){
    .servicesThree{
        .servicesThreeTitle{
            h1{
                font-size: 25px !important;
            }
        }
        .servicesThreeContent{
            .contentText{
                .content1, .content2{
                    h1{
                        font-size: 20px !important;
                    }
                    p{
                        padding: 20px 0px 0px 0px !important;
                    }
                }
            }
            .contentImg{
                width: 100%;
            }
        }
    }
}