.aboutSeven{
    padding: 50px 150px 100px;
    display: grid;
    grid-template-columns: 40% auto;
    justify-content: center;

    .bg1{
        width: 500px;
        position: absolute;
        margin: 100px auto auto -100px;
    }
    .bg2{
        width: 800px;
        position: absolute;
        right: 0px;
        opacity: 5%;
    }
    .bg3{
        z-index: 1 !important; 
        width: 150px !important;
        position: relative;
        margin: auto -100px -50px auto;
    }
    .phone{
        display: grid;
        grid-template-areas: 'phone';
        justify-self: center;
        align-items: center;
        width: 280px;

        img{
            grid-area: phone;
            width: 100%;
            z-index: 2;
            justify-self: center;
        }
        video{
            grid-area: phone;
            width: 93.5%;
            position: relative;
            left: -1px;
            justify-self: center;
            z-index: 3;
        }
    }
    .text{
        max-width: 600px;
        h1{
            font-size: 30px;
            background-image: linear-gradient(90deg, #000 0%, #D30B0B 40%);
            background-clip: text;
            -webkit-text-fill-color: transparent;
            font-weight: 600;
            margin: 0px;
            padding-top: 100px;
        }
        p{
            padding-top: 20px;
            font-size: 18px;
            line-height: 35px;
        }
    }
}

@media screen and (max-width: 1600px){
    .aboutSeven{
        .bg1{
            margin-left: -200px;
        }
        .text{
            max-width: 500px;
            h1{
                font-size: 25px;
            }
            p{
                font-size: 16px
            }
        }
        .phone{
            justify-self: left;
        }
    }
}

@media screen and (max-width: 1440px){
    .aboutSeven{
        .phone{
            width: 250px;
        }
    }
}

@media screen and (max-width: 1200px){
    .aboutSeven{
        padding: 30px 50px 50px;
    }
}

@media screen and (max-width: 1000px){
    .aboutSeven{
        .bg1{
            width: 400px;
        }
        .text{
            h1{
                padding-top: 50px;
            }
            p{
                line-height: 30px;
            }
        }
        .phone{
            width: 200px;
        }
    }
}

@media screen and (max-width: 768px){
    .aboutSeven{
        .text{
            p{
                line-height: 25px;
            }
        }
        .phone{
            width: 200px;
        }
    }
}

@media screen and (max-width: 700px){
    .aboutSeven{
        padding: 0px 30px 20px;
        background-image: none;
        grid-template-columns: auto auto;
        gap: 50px;

        .bg1, .bg3{
            display: none;
        }
        .text{
            grid-column-start: 1;
            grid-column-end: 2;
            grid-row-start: 1;
            grid-row-end: 2;
        }
        .phone{
            grid-column-start: 2;
            grid-column-end: 3;
            grid-row-start: 1;
            grid-row-end: 2;
        }
    }
}

@media screen and (max-width: 600px){
    .aboutSeven{
        grid-template-columns: 100%;
        gap: 0px;
        
        .text{
            h1{
                padding-top: 0px;
            }
        }
        .phone{
            padding-top: 30px;
            justify-self: center;
            grid-column-start: 1;
            grid-column-end: 2;        
            grid-row-start: 2;
            grid-row-end: 3;
        }
    }
}

@media screen and (max-width: 425px){
    .aboutSeven{
        padding: 20px 30px;

        .phone{
            padding-top: 30px;
            width: 80%;
            justify-self: center;
        }
        .text{
            h1{
                font-size: 22px;
            }
        }
    }
}

@media screen and (max-width: 375px){
    .aboutSeven{
        .phone{
            width: 100%;
        }
    }
}