.services {

    background-image: linear-gradient(90deg, rgba(211, 11, 11, 0) 72.42%, #d50e0e 345.36%), url(../img/home/background_services.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 100% 15%;
    overflow: hidden;

    padding: 8rem 0rem 5rem 0rem;

    .servicesTitle{
        transform: translateY(50%);
        opacity: 0%;
    }
    .servicesTitle.visible{
        animation: slideIn 1s 0.4s ease-out forwards;
    }
    .services-flex {
        padding-top: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        gap: 30px;
    }

    .item {
        opacity: 0%;
        flex: 0 0 300px;
        transform: translateX(100%);
        display: flex;
        align-items: center;
        flex-direction: column;

        img {
            width: 50px;
            color: #D30B0B;
            margin-bottom: 10px;
        }

    }



    .item1 {
        animation: slideIn 1s 0.4s ease-out forwards;
    }

    .item2 {
        animation: slideIn 1s 0.6s ease-out forwards;
    }

    .item3 {
        animation: slideIn 1s 0.8s ease-out forwards;
    }

    .item4 {
        animation: slideIn 1s 1s ease-out forwards;
    }

    .item5 {
        animation: slideIn 1s 1.2s ease-out forwards; 
    }

    .item6 {
        animation: slideIn 1s 1.4s ease-out forwards;
    }

    .item7 {
        animation: slideIn 1s 1.6s ease-out forwards;
    }

    .item8 {
        animation: slideIn 1s 1.8s ease-out forwards;
    }

    .item9 {
        animation: slideIn 1s 2s ease-out forwards;
    }


    @keyframes slideIn {
        to {
            opacity: 100%;
            transform: translate(0);
        }
    }

    .servicesTitle {
        text-align: center;
        font-size: 30px;
        font-weight: 600;

        padding: 2rem 1rem 50px 1rem;

        background: linear-gradient(90deg, #000 30%, #D30B0B 60%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    h2 {
        font-size: 18px;
        text-align: center;
        color: var(--cor-primary);
    }

    p {
        font-size: 14px;
        text-align: center;
    }

    .teste{
        padding-top: 40px;
        opacity: 0%;
        transform: translateY(100%);
        transition: 1s 3s ease-in-out;
        display: grid;
        justify-content: center;
        
        a{
            text-decoration: none;
        }
        .button{
            cursor: pointer;
            display: grid;
            grid-template-columns: auto auto;
            width: 250px;
            height: 40px;
            gap: 5px;
            justify-content: center;
            align-items: center;
            border-radius: 50px;
            border: 2px solid #D30B0B;
            background-color: #ffffff00;
            transition: 0.5s ease;
    
            h1{
                margin: 0px 5px 0px 0px;
                letter-spacing: -2px;
                font-size: 25px;
                background: linear-gradient(90deg, #5c0000 30%, #a80404 60%);
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                padding-right: 2px;
                font-weight: 500;
                transition: 0.8s;
            }
            img{
                transition: .8s ease;

            }
        }
        
        .button:hover{
            background-color: #D30B0B;
            border-radius: 10px;
            
            h1{
                transform: translateX(30px);
                background: linear-gradient(90deg, #ffffff 30%, #ffffff 60%);
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
            img{
                transform: translateX(-110px);
                filter: brightness(0%);
            }
        }
    }
    .teste.visible{
        opacity: 100%;
        transform: translateY(0%);
    }
}

@media screen and (max-width: 1600px){
    .services{
        .servicesTitle{
            font-size: 25px !important;
        }
        .teste{
            .button{
                height: 35px;
                border: 1px solid #D30B0B;
                h1{
                    font-size: 20px !important;
                }
            }
        }
    }
}


@media screen and (max-width: 1000px) {
    .services{
        .servicesTitle{
            font-size: 25px !important;
        }   
        .teste{
            .button{
                width: 150px;
                
                h1{
                    font-size: 18px;
                }
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .services{
        padding: 4rem 0rem 5rem 0rem;
    }
}

@media screen and (max-width: 500px) {
    .services{
        h2{
            font-size: 17px;
        }
        .servicesTitle{
            font-size: 22px !important;
        }
    }

}